export const PAGE = {
    COACHES:0,
    MY_COACHES:1,
    RESOURCES:2,
    EVENTS:3,
    SETTINGS:4,
    PROFILE:5,
    PREVIEW:6,
    SOCIAL:7,
    AFFILIATE:8,
    NOTIFICATIONS:9,
    AUDIENCE:10,
    GROUP:11,
    STATISTICS:12,
    INTEGRATIONS:13
}
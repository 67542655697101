import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import Detail from '../components/Detail';
import SocialIcon from '../components/icons/SocialIcon';
import TypeIcon from '../components/icons/TypeIcon';
import BibleScripture from '../components/BibleScripture';
import TextBlock from '../components/TextBlock';
import GoogleDoc from '../components/GoogleDoc';

function PageGroupPreview() {

    const {groupId} = useParams();
    const [group, setGroup] = useState(null);
    const [image, setImage] = useState("");
    const [resources, setResources] = useState([]);
    const [social, setSocial] = useState([]);
    const history = useHistory();


    useEffect(() => {
        async function run(){
            try{
                const res = await API.GetAPI('/api/groups/group/'+groupId);
                 
                if(res.group){
                    setResources(res.resources);
                    setGroup(res.group);
                    setImage(res.image);
                    setSocial(res.social);
                }
        
            }catch(e){
                console.log(e.message);
            }
        }

        run();
    }, [groupId]);

    const onUrl = (url) => window.open(url, "_blank")
    
    const Resource = ({res}) => {
        return (
            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                    overlay={<Tooltip id={res.name}>{res.description}</Tooltip>}>
                        <div key={res.url}>
                            <Button style={{minWidth:400}} variant="light" className="dropShadow hover" onClick={()=>onUrl(res.url)}>
                                {res.type === "Link" ? <SocialIcon url={res.url}/> : <TypeIcon type={res.type}/>}
                                <strong><Detail text={res.name} /></strong>
                            </Button>
                        <br /><br />
                    </div>
            </OverlayTrigger>
        )
    }


    if(!group){
        return <AnimationLoading title="Loading Content..."/>
    }

    return (
        <Container className="centralise">
            <br/><br/>
            <Button variant="light" onClick={()=>history.push('/dashboard')} className="dropShadow hover round">
                <strong>BACK TO DASHBOARD</strong>
            </Button>
            <br/><br/><br/><br/>
            <img src={image} alt="logo" className="dropShadow" style={{borderRadius:"50%", width:160, height:160, padding:10}}/>
            <h1>{group.name}</h1>
            <h2><i>{group.description}</i></h2>
            <br/>
            <hr/>
            <Row xs={3} sm={social.length}>
                {
                social.map((link, index)=> 
                    <Col key={index}>
                        <Button variant="light" className="round dropShadow" onClick={()=>onUrl(link.url)}>
                            <img src={link.image} alt={link.name} width={50}/>
                        </Button>
                    </Col>
                )
                }
            </Row>
            
            <br/>
            {
                resources.map((res, index) => {
                    if (res.show) {
                        if(res.type === "Video"){
                            return <Resource key={index} res={res}/>
                        } else if(res.type === "Image"){
                            return <img src={res.url} alt={res.name} style={{maxWidth:640}} />
                        } else if(res.type === "Google Doc"){
                            return <GoogleDoc res={res} />
                        }else if(res.type === "Bible Scripture"){
                            return <BibleScripture res={res}/>
                        } else if(res.type === "Text"){
                            return <TextBlock res={res}/>
                        } else {
                            return <Resource key={index} res={res}/>
                        }
                    } else {
                        return <Resource key={index} res={res}/>
                    }
                })
            } 
            <br/>
            <img src={group.bottom_branding} alt="branding" height={200} />
        </Container>
    )
}

export default PageGroupPreview

import React, { useEffect, useState, useContext } from 'react'
import { Alert, Button, Container, Form, FormControl, InputGroup, Modal, ProgressBar } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import swal from 'sweetalert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import LinkIcon from '@mui/icons-material/Link';
import { getStorage, ref,uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { ContextUser } from '../App';


function PageGroupEdit() {

    const {groupId} = useParams();
    const user = useContext(ContextUser);
    const [group, setGroup] = useState(null);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [publicGroup, setPublicGroup] = useState(true);
    const [password, setPassword] = useState("");
    const [description, setDescription] = useState("");
    const [resources, setResources] = useState([]);
    const [bottomBranding, setBottomBranding] = useState("");
    const [progress, setProgress] = useState(0);
    const [progressPopup, setProgressPopup]= useState(false);
    const history = useHistory();
    

    useEffect(() => {
        async function run(){
            try{
                const res = await API.GetAPI('/api/groups/group/'+groupId);
                if(res.group){
                    setGroup(res.group);
                    setName(res.group.name);
                    setCode(res.group.code);
                    setDescription(res.group.description);
                    setBottomBranding(res.group.bottom_branding);
                }
        
            }catch(e){
                console.log(e.message);
            }
        }

        run();
    }, [groupId]);

    useEffect(() => {
        async function run(){
            try{
                const resources = await API.GetAPI(`/api/resource`);
                setResources(resources);
            }catch(e){
                console.log(e.message);
            }
        }
       run();
    }, []);

    const onUpdateImage = async (e) => {
        
        const file = e.target.files[0];
        const mbs = 2;
        const maxSize = mbs * 1000 * 1000;

        if(file.size > maxSize){
            swal(`Image is too large. Image should be less than ${mbs}MB`); 
            return;
        }

        if(!file.type.match(/image/gmi)){
            swal(`Invalid image`); 
            return;
        }

        // Create a root reference
        const storage = getStorage();

        // Create a reference to 'profile.png'
        const storageRef = ref(storage, `${user._id}/group/${groupId}/brand.png`);

        const uploadTask = uploadBytesResumable(storageRef, file);

        const onStateChange =  (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(parseInt(progress));
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                console.log('Upload is paused');
                break;
                case 'running':
                console.log('Upload is running');
                break;
                default:break;
            }
        }

        const onError = (error) => {
            swal(error.message)
            setProgressPopup(false)
        }

        const onComplete = async () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            try{
                const update =  {
                    image:downloadURL
                };
                setLoading(true);
                const res = await API.PatchAPI(`/api/groups/${groupId}/brand`, update);
                setBottomBranding(downloadURL);
                swal(res.message);
            }catch(e){
                console.log(e.message)
            }finally{
                setLoading(false);
                setProgressPopup(false)
            }
        }

        
        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', onStateChange, onError, onComplete);
        setProgressPopup(true);
    }


    const onEdit = async (e) => {
        e.preventDefault();

        const listOfResources = [];
        resources.forEach(res => {
            if(group.resources.find(rId => rId === res._id)){
                listOfResources.push(res._id)
            }
        });

        const groupPayload = {
            name:name, 
            code:code, 
            description:description, 
            resources:listOfResources, 
            social:{},
            public: publicGroup,
            password: password,
        }
        
        try{
            setLoading(true);
            const res = await API.PatchAPI('/api/groups/'+group._id, groupPayload);
            swal(res.result? 'Group updated' : 'Group Not Updated', res.message, res.result? 'success': 'info');
        }catch(e){
            console.log(e.message);
            swal('Something went wrong. Check your internet.')
        }finally{
            setLoading(false);
        }
    }

    

    const onDragEnd = (result) => {
        if (!result.destination) return;
    
        const items = resources;
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setResources([...items]);
    }
    
    const isResourceThere = (res) => {
        const r = group.resources.find( rId => rId === res._id);
        return r !== null && r !== undefined;
    }

    const handleToggle = (res) => {

        const resourceIndex = group.resources.findIndex(rId => rId === res._id);
        if(resourceIndex !== -1){
            delete group.resources[resourceIndex];
        }else{
            group.resources.push(res._id);
        }
        setGroup({...group});
    };

    const onBranding = () => document.getElementById('input').click();

    if(!group){
        return <AnimationLoading title="Loading Content..."/>
    }

    return (
        <Container>
            <br/><br/>
            <Button variant="light" onClick={()=>history.push('/dashboard')} className="dropShadow hover round">
                <strong>BACK TO DASH BOARD</strong>
            </Button>
            <br/><br/>
            <Form onSubmit={onEdit} className="centralise">    
            <Alert variant="light" className="dropShadow">
                <Alert.Heading><h2>Update {group.name}</h2></Alert.Heading>
                <hr/>
                <Form.Label>Group Name ({name.length}/50)</Form.Label>
                <FormControl placeholder="Group Name" type="text" size="lg" name="name" maxLength={50} value={name} onChange={e=>setName(e.target.value)} />
                <br/>
                <Form.Label>Join Code ({code.length}/50)</Form.Label>
                <FormControl placeholder="Join Code" name="code" type="text" maxLength={50} value={code} onChange={e=>setCode(e.target.value)}/>
                    
                <br/>
                <Form.Label>Description ({description.length}/200)</Form.Label>
                <FormControl size="lg" placeholder="Description" value={description} onChange={e=>setDescription(e.target.value)} type="text" as="textarea" style={{height:80}} maxLength={200} />
                <br/>
                    {
                    publicGroup ? null :
                     <div>
                        <Form.Label>Group Password ({password.length}/50)</Form.Label>
                        <FormControl  placeholder="Group Password" value={password} onChange={e=>setPassword(e.target.value)} type="password" maxLength={50} />
                    </div>
                    }
                    <br/>
                    <Form.Check checked={!publicGroup} onChange={e=>setPublicGroup(!publicGroup)} label="Group is private" />
                <br/>
                
                <br/><br/>
                <Button disabled={loading} variant="info" type="submit" className="round hover dropShadow">
                    <strong>{loading?"LOADING...":`UPDATE ${group.name.toUpperCase()}`}</strong>
                </Button>  
            </Alert>
        </Form>
        <br/>
        <Alert variant="light" className="dropShadow centralise">
            <Alert.Heading>
                <h1>Branding</h1>
            </Alert.Heading>
            <hr/>
            <input id="input" onChange={onUpdateImage} type="file" style={{display:"none"}} accept="image/*"/>
            <img height={200} src={bottomBranding} alt="brand"/>
            <br/><br/>
            <Button variant="primary" className="dropShadow hover round" onClick={onBranding}>
                <strong>Change Branding</strong>
            </Button>
        </Alert>
        <br/>
        {/**https://www.youtube.com/watch?v=y1w6C9A5a2A */}
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="social">
                            {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                <List className="dropShadow" sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    subheader={
                                    <ListSubheader>
                                        <h2>Resources</h2>
                                        <h5><i>Drag and Drop the item to organise resource listing</i></h5>
                                        <hr/>
                                    </ListSubheader>
                                    }>

                                {resources.map((res, index) => {
                                return (
                                    <Draggable key={'d'+index} draggableId={'j'+index} index={index}>
                                    {(provided) => (
                                        <Alert variant="light" style={{alignContent:"left", alignItems:"left"}} className="dropShadow" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <ListItem>
                                                <ListItemIcon> <LinkIcon /></ListItemIcon>
                                                <ListItemText id="switch-list-label-wifi" primary={<Resources res={res}/>} />
                                                <Switch edge="end"
                                                    onChange={(value) => handleToggle(res)}
                                                    checked={isResourceThere(res)}
                                                    inputProps={{ 'aria-labelledby': 'switch-list-label-wifi', }}
                                                />
                                            </ListItem>
                                        </Alert>
                                    )}
                                    </Draggable>
                                );
                                })}
                                {provided.placeholder}
                                </List>
                            </div>
                            )}
                        </Droppable>
                    </DragDropContext>     
            <Modal show={progressPopup} onHide={()=>setProgressPopup(false)}>
                <Modal.Header>
                    <Modal.Title>Updating Branding</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Uploading {progress}%</h4>
                    <br/>
                    <ProgressBar style={{height:15}} variant="primary" min={0} max={100} now={progress} striped={true} animated={true} />
                </Modal.Body>
            </Modal>              
    </Container>
    )
}

export default PageGroupEdit


function Resources ({res}){

    const onCopy = ()=> {
        navigator.clipboard.writeText(res.url).then(()=>{
            swal(res.name, `${res.name} link has been copied`, 'success');
        })
    };


    return <div>
        <h6><strong>{res.name}</strong></h6>
        <h6>{res.description}</h6>
        <Button variant="light" className="hover round" onClick={onCopy}>
            <LinkIcon />
            {" "}
            Copy
        </Button>
    </div>
}
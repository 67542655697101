import React, { useContext, useEffect, useState } from 'react'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

// ProSideBar
import { ProSidebar, Menu, MenuItem, SubMenu , SidebarHeader, SidebarFooter, SidebarContent} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { getAuth, signOut} from "firebase/auth";

// https://react-icons.github.io/react-icons/icons?name=ai
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { AiFillAudio, AiTwotoneApi } from "react-icons/ai";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { IconContext } from 'react-icons/lib';
import { FaThList } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi';
import { MdOutlineSocialDistance } from 'react-icons/md';
import { IoIosPeople , IoIosSettings} from 'react-icons/io';
import { BiBell, BiGroup, BiStats } from 'react-icons/bi';
import { PAGE } from "../utils/pages"
import { ContextSetUser, ContextUser } from '../App';
import { AnimationLoading } from '../components/Lottie';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import ContentResources from '../content/ContentResources';
import ContentCoaches from '../content/ContentCoaches'; 
import ContentSocial from '../content/ContentSocial';
import ContentNotifications from '../content/ContentNotifications';
import ContentAudience from '../content/ContentAudience';
import ContentGroup from '../content/ContentGroup';
import ContentStatistics from '../content/ContentStatistics';
import * as API from "../utils/api";
import swal from 'sweetalert';
import ContentIntegrations from '../content/ContentIntegrations';
import ContentAffilate from '../content/ContentAffilate';

const PageDashboard = ()=> {
    
    const iconSize = "1.5em";
    const history = useHistory();

    const user = useContext(ContextUser);
    const setUser = useContext(ContextSetUser);

    const [collapse, setCollapse] = useState(false);
    const [page, setPage] = useState(PAGE.COACHES)
    const [resources, setResources] = useState([]);
   
    useEffect(() => {
        async function run(){
            if(user){
                const resources = await API.GetAPI(`/api/resource`);
                setResources(resources);
            }else{
                try{
                    const profile = await API.GetAPI('/api/user');
                    if(profile?.result){
                        const u = profile.user;
                        u.share_url = profile.share_url;
                        u.register_url = profile.register_url;
                        setUser(u);
                    }else{
                        history.push('/');
                    }
                }catch(e){
                    history.push('/');
                }
            }
        }
       run();
    }, [user, history, setUser]);

    const onLogout = async () => {
        Cookies.set(process.env.REACT_APP_COOKIE_KEY, null);
        await signOut(getAuth());
        setUser(null);
        history.push('/');
        swal('Logout', 'Logout Successful. Please Come Again', 'success');
    }

    const selectedColor = (pageIndex) => {
        return page === pageIndex ? "#DDDDDD": "#0095AF";
    }

    const openBOSDashboard = () => {
        window.open(process.env.REACT_APP_DASHBOARD_URL, "_blank")
    }

    if(!user){
        return <AnimationLoading width={700} title="Loading..." />
    }

    return (
        <>
            <ProSidebar style={{position:"fixed"}} collapsed={collapse}>
                <SidebarHeader>
                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="user"><h6>{user.email}</h6></Tooltip>}>
                        <Menu iconShape="circle">
                            <MenuItem onClick={()=>setCollapse(!collapse)} icon={<img src={user?.image} alt="logo" width={70} height={70} style={{borderRadius:"50%", padding:14}} />}>{user?.name}</MenuItem>
                        </Menu>
                    </OverlayTrigger>
                </SidebarHeader>
                
                <SidebarContent>
                    <Menu iconShape="square">

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Profile"><h6>Profile</h6></Tooltip>}>
                            <MenuItem onClick={()=>setPage(PAGE.COACHES)} icon={<IconContext.Provider value={{ color: selectedColor(PAGE.COACHES), size:iconSize}}><IoIosPeople /></IconContext.Provider>}>Profile</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Social"><h6>Social Links</h6></Tooltip>}>
                            <MenuItem onClick={()=>setPage(PAGE.SOCIAL)} icon={<IconContext.Provider value={{ color: selectedColor(PAGE.SOCIAL), size:iconSize}}><MdOutlineSocialDistance /></IconContext.Provider>}>Social Links</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Resources"><h6>My Links</h6></Tooltip>}>
                            <MenuItem onClick={()=>setPage(PAGE.RESOURCES)} suffix={<Badge pill variant="dark">{resources.length === 0?"Add New":resources.length}</Badge>} icon={<IconContext.Provider value={{ color: selectedColor(PAGE.RESOURCES), size:iconSize}} > <FaThList /></IconContext.Provider>}>My Links</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Social"><h6>Content</h6></Tooltip>}>
                            <MenuItem onClick={()=>setPage(PAGE.GROUP)} icon={<IconContext.Provider value={{ color: selectedColor(PAGE.GROUP), size:iconSize}}><BiGroup /></IconContext.Provider>}>Content</MenuItem>
                        </OverlayTrigger>
                         
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Statistics"><h6>Statistics</h6></Tooltip>}>
                            <MenuItem onClick={()=>setPage(PAGE.STATISTICS)} icon={<IconContext.Provider value={{ color: selectedColor(PAGE.STATISTICS), size:iconSize}}><BiStats /></IconContext.Provider>}>Statistics</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Audience"><h6>Audience</h6></Tooltip>}>
                            <MenuItem onClick={()=>setPage(PAGE.AUDIENCE)} icon={<IconContext.Provider value={{ color: selectedColor(PAGE.AUDIENCE), size:iconSize}}><AiFillAudio /></IconContext.Provider>}>Audience</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Affiliates"><h6>Affiliates</h6></Tooltip>}>
                            <MenuItem onClick={()=>setPage(PAGE.AFFILIATE)} icon={<IconContext.Provider value={{ color: selectedColor(PAGE.AFFILIATE), size:iconSize}}><HiUserGroup /></IconContext.Provider>}>Affiliates</MenuItem>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Notifications"><h6>Notifications</h6></Tooltip>}>
                            <MenuItem onClick={()=>setPage(PAGE.NOTIFICATIONS)} icon={<IconContext.Provider value={{ color: selectedColor(PAGE.NOTIFICATIONS), size:iconSize}}><BiBell /></IconContext.Provider>}>Notifications</MenuItem>
                        </OverlayTrigger>
 
                    </Menu>
                </SidebarContent>
                
                <SidebarFooter>
                    <Menu iconShape="round">

                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="collapse"><h6>BOS Applications Dashboard</h6></Tooltip>}>
                            <MenuItem onClick={openBOSDashboard} icon={<img src="https://firebasestorage.googleapis.com/v0/b/bosapplications.appspot.com/o/logo512.png?alt=media&token=77dba803-7066-45d7-a778-1b6bc72fe1e7" alt="bos" width={20}/>}>BOS Applications</MenuItem>
                        </OverlayTrigger>

                        <SubMenu title="Settings" icon={<IconContext.Provider value={{ color: "#DDDDDD", size:iconSize}}><IoIosSettings /></IconContext.Provider>}>
                            <MenuItem onClick={()=>setPage(PAGE.INTEGRATIONS)}>Integrations</MenuItem>
                            <MenuItem onClick={()=>setPage(PAGE.INTEGRATIONS)}>Security</MenuItem>
                        </SubMenu>
                                                
                        <SubMenu title="About Us" icon={<IconContext.Provider value={{ color: "#DDDDDD", size:iconSize}}><AiTwotoneApi /></IconContext.Provider>}>
                            <MenuItem><a target="_blank" rel="noreferrer" href="https://app.mysocial360.com/terms">Terms Of Service</a></MenuItem>
                            <MenuItem><a target="_blank" rel="noreferrer" href="https://app.mysocial360.com/privacypolicy">Privacy Policy</a></MenuItem>
                        </SubMenu>


                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Logout"><h6>Logout</h6></Tooltip>}>
                            <MenuItem onClick={onLogout} icon={<IconContext.Provider value={{ color: "#BB0000", size:iconSize}}><RiLogoutCircleRFill/></IconContext.Provider>}>Logout</MenuItem>
                        </OverlayTrigger>
                        

                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="collapse"><h6>{collapse? "Expand":"Collapse"}</h6></Tooltip>}>
                            <MenuItem onClick={()=>setCollapse(!collapse)} icon={<IconContext.Provider value={{ color: "#FFFFFF", size:iconSize}}>{collapse ? <FaAngleDoubleRight/> : <FaAngleDoubleLeft/> }</IconContext.Provider>}>{collapse? "Expand":"Collapse"}</MenuItem>
                        </OverlayTrigger>                        
                    </Menu>
                </SidebarFooter>
            </ProSidebar>
            <main style={{zIndex:0, position:"absolute", top:100, left:130, height:"80%", width:"90vw", overflowY: "visible"}}>
                {page === PAGE.COACHES ? <ContentCoaches menuCollapse={collapse}/> :null}
                {page === PAGE.SOCIAL ? <ContentSocial menuCollapse={collapse}/> :null}
                {page === PAGE.RESOURCES ? <ContentResources resources={resources} setResources={setResources} menuCollapse={collapse}/> :null}
                {page === PAGE.NOTIFICATIONS ? <ContentNotifications menuCollapse={collapse}/> :null} 
                {page === PAGE.AFFILIATE ? <ContentAffilate menuCollapse={collapse}/> :null} 
                {page === PAGE.AUDIENCE ? <ContentAudience menuCollapse={collapse}/> :null} 
                {page === PAGE.GROUP ? <ContentGroup menuCollapse={collapse}/> :null}
                {page === PAGE.STATISTICS ? <ContentStatistics menuCollapse={collapse}/> :null}
                {page === PAGE.INTEGRATIONS ? <ContentIntegrations menuCollapse={collapse}/> :null}
            </main>
        </>
    )
}

export default PageDashboard

import React, { useEffect, useState } from 'react'
import {  Button, Col, Container, Form, FormControl, Modal, Row, Table } from 'react-bootstrap'
import swal from 'sweetalert';
import Detail from '../components/Detail';
import SocialIcon from '../components/icons/SocialIcon';
import Switch from "react-switch";
import * as API from "../utils/api";
import * as BIBLE from "../utils/bible";


function ContentResources({menuCollapse, resources, setResources}) {

    //constants
    const types = ["Link", "Video", "Image", "Text", "PDF", "Google Doc",  "Bible Scripture"];
    const MAX_DESC_LENGTH = 600;
    
    const [loading, setLoading] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [url, setUrl] = useState("");
    const [name, setName] = useState("");
    const [show, setShow] = useState(false);
    const [description, setDescription] = useState("");
    const [resourceType, setResourceType] = useState("-1");
    const [bibleBook,setBibleBook] = useState(1);
    const [bibleChapter, setBibleChapter] = useState(1);
    const [bibleVerse, setBibleVerse] = useState(1);
    const [popup, setPopup] = useState(false);
    const bibleBooks = BIBLE.books;

    useEffect(() => {
          const run  = async() =>{
                if(resourceType === "Bible Scripture"){
                    const scripture = await API.Get(`https://app.mysocial360.com/bible360/api/bible/read/net/${bibleBook}/${bibleChapter}/${bibleVerse}`);
                    if(scripture){
                        setDescription(scripture.replace(/<[^>]*>/gm, ''));
                        setUrl(`https://app.mysocial360.com/bible360/api/bible/read/net/${bibleBook}/${bibleChapter}/${bibleVerse}`)
                    }
                }           
          }
          run();
    }, [resourceType, bibleBook, bibleChapter, bibleVerse])


    const onAdd = async (e) => {
        e.preventDefault();

        let link = "https://app.mysocial360.com";
        if(resourceType === "Bible Scripture"){
            link = `https://app.mysocial360.com/bible360/api/bible/read/net/${bibleBook}/${bibleChapter}/${bibleVerse}`
        }
        

        const res =   {
            name: e.target.name.value,
            description:e.target.description.value,
            url: e.target.url ? e.target.url.value : link,
            type:e.target.type.value,
            show: show,
        } 

        if(resourceType === "Bible Scripture"){
            res.data = {
                book:bibleBook,
                bookName: bibleBooks[bibleBook],
                chapter:bibleChapter,
                verse: bibleVerse,
            }
        }

        //check if the resource url exists
        if(resources.find(r => r.name === res.name) && editIndex === -1){
            swal(`Resources with url ${res.url} already exists`);
        }else if(description.length > MAX_DESC_LENGTH){
            swal(`Description TOO long`);
        }else{

            try{
                setLoading(true);

                // POST or PATCH (add or update)
                const response = editIndex === -1 ? 
                    await API.PostAPI('/api/resource', res) : 
                    await API.PatchAPI(`/api/resource/${editIndex}`, res);
                
                if(response.result){

                    swal(response.message);
                    const resources = await API.GetAPI(`/api/resource`);
                    setResources(resources);
                    //clear fields
                    e.target.name.value = "";
                    e.target.description.value = "";
                    if(e.target.url){
                        e.target.url.value = "";
                    }
                    
                    setShow(false);
                    setUrl("")
                    setDescription("");
                    setEditIndex(-1);
                }
            
            }catch(e){
                console.log(e.message);
            }finally{
                setLoading(false);
            }
            
        }
    }

    const onDel = async (res) => {
        const name = resources.find(r => r.name === res.name).name
        const options = {
            title: `Delete ${name}`,
            text: `Do you want to delete blocked option for ${name}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }
        const result = await swal(options)
        

        //when user accepts delete option
        if (result) {
            const list = [];
            resources.forEach(resource=>{
                if(resource.name !== res.name){
                    list.push(resource);
                }
            });
            setResources(list);
        } 
    }

 const onEditingResources = (res) => {
        window.scrollTo(0, 0); 
        setEditIndex(res._id);
        setName(res.name);
        setDescription(res.description);
        setResourceType(res.type);
        setUrl(res.url);
        if(res.type === "Bible Scripture"){
            setBibleBook(res.book);
            setBibleChapter(res.chapter);
            setBibleVerse(res.verse);
        } 
        setPopup(true);
    }
    const onClosePopup = () => {
        setEditIndex(-1);
        setShow(false);
        setUrl("")
        setDescription("");
        setEditIndex(-1);
        setPopup(false);
    }

    return (
        <Container fluid={menuCollapse ? "xs" : "xl" } style={{paddingTop:50}} className="centralise">
 
            <h1>My Resources</h1>
            <h4><i>This is a central placing to store all your <strong>resources</strong></i></h4>
            <br/>
            <div className="centralise">
                    <Button variant={loading?"outline-info":"info"} size="lg" className="round hover dropShadow" onClick={()=>setPopup(true)}>
                        <img src="https://img.icons8.com/ios-filled/30/ffffff/link.png" alt="res"/>
                        {" "}
                        <strong>{loading?"Loading":"Add Resource"}</strong>
                    </Button>
            </div>
            <br/>
            <Container>
                <Table variant="light" className="dropShadow">
                    <thead>
                        <th>NAME</th>
                        <th>DESCRIPTION</th>
                        <th>URL</th>
                        <th>TYPE</th>
                        <th>SHOW BLOCK</th>
                        <th>ACTIONS</th>
                    </thead>
                    <thead>
                    {
                        resources.map(res=>
                        <tr key={res._id}>
                            <td>{res.name}</td>
                            <td><Detail text={res.description} max={40}/></td>
                            <td><Detail text={res.url} max={40}/></td>
                            <td>{res.type}</td>
                            <td>{res.show ? "SHOWING" :"NOT SHOWING"}</td>
                            <td>
                                <Button variant="light" className="round hover dropShadow" onClick={()=>onEditingResources(res)}>
                                    <strong>EDIT</strong>
                                </Button>
                                <Button variant="light" className="round hover dropShadow" onClick={()=>onDel(res)}>
                                    <strong>DELETE</strong>
                                </Button>
                            </td>
                        </tr>
                    )}
                    </thead>
                </Table>

                    
            </Container>
            <br/>
            <Modal show={popup} onHide={onClosePopup}>
                <Modal.Header closeButton>
                    <Modal.Title>{editIndex === -1? "ADD Resource" : "Edit " + resources.find(r=>r._id===editIndex).name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={onAdd}>
                        
                        <Form.Label>Name</Form.Label>
                        <FormControl disabled={loading} required name="name" type="name" size="lg" placeholder="Name" value={name} onChange={(e)=>setName(e.target.name.value)}/>
                        <br/>
                        <Form.Label><SocialIcon url={url} />{" "}Type</Form.Label>
                        <FormControl disabled={loading} required name="type" as="select" size="lg" value={resourceType} onChange={(e)=>setResourceType(e.target.value)} placeholder="Resources Type">
                            <option disabled value="-1">Choose Resource Type</option>
                            {types.map(type=><option key={type} value={type}>{type.replace("Bible ", "")}</option>)}
                        </FormControl>
                        <br/>
                        {
                            resourceType === "Bible Scripture" ? 
                            <Row xs={1} sm={2} md={3}>
                                <Col>
                                    <FormControl disabled={loading} required name="book" as="select" size="lg" value={bibleBook} onChange={(e)=>setBibleBook(parseInt(e.target.value))} placeholder="Book">
                                        <option disabled value="-1">Choose Book</option>
                                        {bibleBooks.map((book, index)=><option key={'book'+index} value={index+1}>{book}</option>)}
                                    </FormControl>
                                </Col>
                                <Col>
                                    <FormControl disabled={loading} required name="chapter" size="lg" type="number" min={1} value={bibleChapter} onChange={(e)=>setBibleChapter(parseInt(e.target.value))} placeholder="Chapter"/>
                                </Col>
                                <Col>
                                    <FormControl disabled={loading} required name="verse" size="lg" type="number" min={1} value={bibleVerse} onChange={(e)=>setBibleVerse(parseInt(e.target.value))} placeholder="Verse"/>
                                </Col>
                            </Row>
                            :
                            <>
                                <Form.Label>Url</Form.Label>
                                <FormControl disabled={loading} required name="url" type="url" value={url} onChange={(e)=>setUrl(e.target.value)}size="lg" placeholder="URL link for resources" max={300}/>
                            </>
                        }
                        <br/>
                        <Form.Label>Show In Frame</Form.Label>
                        <br/>
                        <Switch name="show" checked={show} onChange={setShow} width={63} height={25} />
                        <br/><br/>
                        <Form.Label style={{color:description.length > MAX_DESC_LENGTH ? "red":""}}>Brief Description ({description.length}/{MAX_DESC_LENGTH})</Form.Label>
                        <FormControl disabled={loading} required name="description" onChange={(e) => setDescription(e.target.value)} value={description} type="text" size="lg" as="textarea" style={{height:100}} placeholder="Brief description of the resource..." max={MAX_DESC_LENGTH}/>
                        <br/>
                        <br/>
                        <button type="submit" style={{display:"none"}} id="button"/>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClosePopup}>
                        <strong>CANCEL</strong>
                    </Button>
                    <label htmlFor="button">
                        <Button variant="info" onClick={()=>document.getElementById('button')?.click()}>
                            <strong>{editIndex === -1?"ADD":"EDIT"}</strong>
                        </Button>
                    </label>
                </Modal.Footer> 
            </Modal>
        </Container>
    )
}

export default ContentResources

import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Form, FormControl, Modal, Row, Table } from 'react-bootstrap'
import swal from 'sweetalert';
import { ContextUser } from '../App';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";

function ContentAffilate({menuCollapse}) {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [popupMember, setPopupMember]=  useState(null);
    const user = useContext(ContextUser);
    const [icon, setIcon] = useState(user.image);
    const [facebookCount, setFacebookCount] = useState(0);
    const [googleCount, setGoogleCount] = useState(0);


    useEffect(() => {
        async function run(){
            try{
                const members = await API.GetAPI('/api/referral');
                setMembers(members);
                const countF = members.filter(m => m.facebook_id !== "").length;
                const countG = members.filter(m => m.google_id !== "").length;
                setFacebookCount(countF);
                setGoogleCount(countG)
            }catch(e){
                console.log(e.message)
                setMembers([]);
            }
        }

        run();
    }, []);

    const onNotification = async (e) => {
        try{
            e.preventDefault();
            setLoading(true);
            const message  = {
                notificationIds: [popupMember.notificationId],
                title:e.target.title.value,
                subtitle:e.target.subtitle.value,
                message:e.target.message.value,
                icon:icon, 
                from:"61a0aac65bf67300174f85d0"
            }
                        
            const send = await API.PostAPI('/api/notifications/send/gcm',message );
            console.log(send);
            setPopupMember(null);
        }catch(e){
            console.log(e.message);
        }finally{
            setLoading(false);
        }
    }

    const onDownload = () => {
        const emails = members.map(member=>member.email+"\n");
        const blob = new Blob(emails, { type: 'type/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "emails.csv")
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    
    const onCopy = () => {
        let emails = "";
        members.forEach(member => emails += member.email+"\n");
        navigator.clipboard.writeText(emails).then(()=> swal(`You've copied ${members.length} email(s)`));
    }

    const getDate = (member) => {
        const date = new Date(member.createdAt);
        const year= date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        return `${day} ${months[month]} ${year}`;
    }

    return (
        <Container fluid={menuCollapse ? "xs" : "xl" }>
            <h1 className="centralise">MY REFERRAL LIST</h1>
            <hr/>
            <Row xs={2}>
                <Col>
                    <Alert variant="light" className="dropShadow">
                        <Alert.Heading><h4>Audience {members.length === 1 ? <strong>1 Person</strong> :<strong>{members.length} People</strong>}</h4></Alert.Heading>
                        <hr/>
                        <br/>
                        <Row xs={2}>
                            <Col>
                                <h5>
                                    <strong>{facebookCount} From Facebook</strong>
                                    <img  src={"https://img.icons8.com/fluency/24/000000/facebook.png"} alt="facebook"/>
                                    
                                </h5>
                            </Col>
                            <Col>
                                <h5>
                                    <strong>{googleCount} From Google</strong>
                                    <img src={"https://img.icons8.com/color/24/000000/gmail--v1.png"} alt="google"/>
                                   
                                </h5>
                            </Col>
                        </Row>
                        
                    </Alert>
                </Col>
                <Col>
                    <Alert variant="light" className="dropShadow">
                        <Alert.Heading><h4>Emails</h4></Alert.Heading>
                        <hr/>
                        <br/>
                        <Row xs={1} sm={2}>
                            <Col>
                                <Button variant="light" className="round dropShadow" onClick={onDownload}>
                                    <img src="https://img.icons8.com/color/30/000000/csv.png" alt="csv"/>
                                    <strong>Download Email List</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" className="round dropShadow" onClick={onCopy} >
                                    <img src="https://img.icons8.com/color/30/000000/copy.png" alt="csv"/>
                                    <strong>Copy Email List</strong>
                                </Button>
                            </Col>
                        </Row>
                    </Alert>
                </Col>
            </Row>
            <br/><br/>
            
            <h3>
                <img src="https://img.icons8.com/color/40/000000/chrome--v1.png" alt="chrome"/>
                Referrals
            </h3>
            <Table variant="light">
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Name</td>
                        <td>Email</td>
                        <td>Country</td>
                        <td>Date</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        members.map((member, index)=>
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td><img src={member.image} alt="member" width={40} className="round"/>{" "}{member.name}</td>
                                <td>
                                    {member.facebook_id !== "" ? <img className="round" src={"https://img.icons8.com/fluency/24/000000/facebook.png"} alt="facebook"/>:<img src={"https://img.icons8.com/fluency/24/000000/chrome.png"} alt="chrome"/>}
                                    {" "}
                                    {member.email}
                                </td>
                                <td>Malawi</td>
                                <td>
                                    {getDate(member)}
                                </td>
                            </tr>    
                        )
                    }
                </tbody>
            </Table>
            <Modal show={popupMember !== null} onHide={()=>setPopupMember(null)}>
                <Modal.Header>
                    <Modal.Title>SEND NOTIFICATION MESSAGE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onNotification}>
                        {
                            loading? <AnimationLoading title="Loading" width={300}/> :
                        <>
                            <img src={icon} height={80}  width={80}  alt="Icon"/>
                            <br/>
                            <Form.Label style={{float:"left"}}><h6>Icon Link</h6></Form.Label>
                            <FormControl required type="url" placeholder="Icon Link" size="lg" value={icon} onChange={(e)=>setIcon(e.target.value)} name="icon" /> 
                            
                            <br/>
                            <Form.Label style={{float:"left"}}><h6>Title</h6></Form.Label>
                            <FormControl required type="text" placeholder="Title" maxLength={60} size="lg"  name="title" /> 
                            <br/>
                            <Form.Label style={{float:"left"}}><h6>Subtitle</h6></Form.Label>
                            <FormControl type="text" placeholder="Subtitle" maxLength={60} size="lg"  name="subtitle"/> 
                            <br/>
                            <Form.Label style={{float:"left"}}><h6>Message</h6></Form.Label>
                            <FormControl required type="text" as="textarea" placeholder="Message..." maxLength={120} size="lg"  name="message" /> 
                            <br/>
                            <Form.Label style={{float:"left"}}><h6>Notification Link</h6></Form.Label>
                            <FormControl type="url" placeholder="Notification Link" size="lg" name="url" /> 
                            <br/>
                            <button id="button-notification" style={{display:"none"}} type="submit"></button>
                        </>
                        }
                       </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={()=>setPopupMember(null)}>
                        <strong>CANCEL</strong>
                    </Button>
                    <Button variant="primary" size="lg" onClick={()=>document.getElementById('button-notification').click()}>
                        <strong>SEND</strong>
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default ContentAffilate

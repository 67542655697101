import React, { useContext } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { ContextUser } from '../App';
import { AnimationCoaching } from '../components/Lottie'
import logo from "../images/logo.png";

function PageHome() {

    const user = useContext(ContextUser);

    return (
        <>
        <Container style={{paddingTop:150}}>
            <Row xs={1} sm={2}>
               
                <Col className="centralise">
                    <img src={logo} alt="logo" height={235}/>
                    <h1>Inner Circle Deets</h1>
                    <p>“Advice is like snow; the softer it falls, the longer it dwells upon, and the deeper it sinks into the mind." — Samuel Taylor Coleridge</p>
                    
                    <hr />
                    
                    <Button variant="info" size="lg" className="round hover dropShadow" as={Link} to={user ? "/dashboard":"/account"}>
                        <img src="https://img.icons8.com/ios-glyphs/30/ffffff/login-rounded-right--v1.png" alt="login"/>
                        <strong>{ user ? "Continue To Dashboard" : "Login And Become and Coach"}</strong>
                    </Button>
                </Col>
                <Col>
                    <AnimationCoaching title="Mentorship"/>
                </Col>
            </Row>
            <br />
            <div className="centralise">
                <img src="https://img.icons8.com/stickers/100/000000/apps-tab.png" alt="chrome"/>
                <h3>Inner Circle Deets Apps</h3>
                <h4>Download extension to get your followers on board with your program</h4>
                <br/>
                <Button variant="light" className="round dropShadow" size="lg" as="a" href="https://chrome.google.com/webstore/detail/inner-circle-deets/hnmhcieekokghkakdnngedapbbjcbjif" target="_blank">
                    <img src="https://img.icons8.com/color/40/000000/chrome--v1.png" alt="chrome"/>
                    {" "}
                    <strong>Download Chrome Extension</strong>
                </Button>
                {" "}
                <Button variant="light" className="round dropShadow" size="lg" as="a" href="https://play.google.com/store/apps/details?id=com.bos.innercircledeets" target="_blank">
                    <img src="https://img.icons8.com/fluency/40/000000/google-play.png" alt="chrome"/>
                    {" "}
                    <strong>Download Android App</strong>
                </Button>
            </div>
            
        </Container>
        </>
    )
}
 
export default PageHome

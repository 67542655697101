import React from 'react'

function GoogleDoc({res}) {

    const googleDocId = res.url.replace(/(^https:\/\/docs\.google\.com\/document\/d\/|\/edit.*)/gmi,'');
    
    return (
        <iframe title="Google Document" src={`https://docs.google.com/viewer?authuser=0&amp;srcid=${googleDocId}&amp;pid=explorer&amp;a=v&amp;chrome=false&amp;embedded=true`} height="520" width="1000"></iframe>
    )
}

export default GoogleDoc

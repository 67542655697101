/**
 * author: Martin Kululanga
 * Github: https://github.com/m2kdevelopments
 */


import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";
import ConnectionError from "./components/ConnectionError";
import PageLogin from "./pages/PageLogin";
import PageDashboard from './pages/PageDashboard';
import PageHome from './pages/PageHome';
import PoweredByBOS from './components/PoweredByBOS';
import PageGroupPreview from './pages/PageGroupPreview';
import PageGroupEdit from './pages/PageGroupEdit';
import * as API from './utils/api';
import swal from 'sweetalert';
import PageOAuth from './pages/PageOAuth';


// use Context
export const ContextUser = React.createContext(null);
export const ContextSetUser = React.createContext(null);
 
const App  = () => {

    const [user, setUser] = useState(null);
    
    useEffect(() => {
        
        async function run(){
            
            const profile = await API.GetAPI('/api/user');
            
            if(profile?.result){
                const u = profile.user;
                u.share_url = profile.share_url;
                u.register_url = profile.register_url;
                setUser(u);
            }else if(profile.result === false){
                swal(profile.message);
            }
        }
        run();
    }, []);


    return (
        <>
          <Router>
                <ScrollToTop />
                <Switch>
                    <ContextUser.Provider value={user}>
                    <ContextSetUser.Provider value={setUser}>
                        <Route exact path="/" component={()=><PageHome />}  />
                        <Route exact path="/oauth" component={()=><PageOAuth />}  />
                        <Route       path="/content/preview/:groupId" component={()=><PageGroupPreview />}  />
                        <Route       path="/content/edit/:groupId" component={()=><PageGroupEdit />}  />
                        <Route exact path="/account"  component={()=><PageLogin />}  />
                        <Route exact path="/dashboard" component={()=><PageDashboard />}  />
                        <Route exact path="/404" component={()=><ConnectionError message="Check your internet connection and re-open the extension" tryAgain={false} callback={null} />} />
                    </ContextSetUser.Provider>
                    </ContextUser.Provider>
                </Switch>
                
            </Router>
            <PoweredByBOS/>
        </>
    );
}

export default App;
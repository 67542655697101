import React from 'react'
import { Alert, Button, Container } from 'react-bootstrap'

function TextBlock({res}) {
    return (
        <Container fluid="sm"> 
            <Alert variant="light" className="round hover dropShadow">
                <Alert.Heading><h1>{res.name}</h1></Alert.Heading>
                <hr/>
                <h3><i>{res.description}</i></h3>
                <br/>
                <Button variant="light" className="hover round dropShadow" as="a" target="_blank" href={res.url}>
                    <strong>Click Here For More</strong>
                </Button>
            </Alert>
        </Container>
    )
}

export default TextBlock

/**
 * Author: Martin Kululanga
 * Github: https://github.com/m2kdevelopments
*/

import React, { useContext, useEffect } from 'react'
import { Container } from 'react-bootstrap';
import logo from "../images/logo.png";
import { useHistory, useLocation } from 'react-router-dom';
import { ContextSetUser } from '../App';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";
import Cookies from 'js-cookie';

//https://v5.reactrouter.com/web/example/query-parameters
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function PageOAuth() {

    const history = useHistory();
    const setUser = useContext(ContextSetUser);
    const appToken = useQuery().get('appToken');
    const appName= useQuery().get('appName');


    useEffect(() => {
        const run = async ()=>{

            try{
                Cookies.set(process.env.REACT_APP_COOKIE_KEY, appToken);
                const res = await API.GetAPI('/api/user');
                const user = res.user;
                if(user){
                    setUser(user);
                    history.push('/dashboard');
                }
            }catch(e){
                history.push('/');
            }
        }
        run()
    }, [setUser, history, appToken])


    return (
        <Container style={{paddingTop:140, paddingBottom:40}} className="centralise" fluid>
                <img src={logo} alt="logo" width={200}/>
                <h1>Inner Circle Deets' Dasboard</h1>
                <h4>Logging in via <strong>{appName}</strong></h4>
                <AnimationLoading width={200} title="Loading"/>
        </Container>
    )
}

export default PageOAuth

import React, { useContext, useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap';
import logo from "../images/logo.png";
import * as API from "../utils/api";
import { GoogleAuthProvider,FacebookAuthProvider ,signInWithPopup, getAuth, signOut   } from "firebase/auth";
import swal from 'sweetalert';
import { ContextSetUser } from '../App';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

const PageLogin = () => {

    const setUser = useContext(ContextSetUser);
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function run(){
            try{
                setLoading(true);
                const profile = await API.GetAPI('/api/user');
                if(profile?.result){
                    const u = profile.user;
                    u.share_url = profile.share_url;
                    u.register_url = profile.register_url;
                    setUser(u);
                    history.push('/dashboard');
                }
            }catch(e){
                console.log(e.message);
            }finally{
                setLoading(false);
            }
            
        }  
        run();
    }, [history, setUser]);


    const onGoogle = async () => {
        try {
            setLoading(true);
            await signOut(getAuth());
            const auth = getAuth();
            const provider = new GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            provider.setCustomParameters({'display': 'popup'});
            const result = await signInWithPopup(auth, provider);

            const access_token = result["_tokenResponse"].oauthAccessToken;
            const res = await API.PostAPI('/api/google/oauth', {access_token:access_token}, false);
            const token = res.access_token;
            Cookies.set(process.env.REACT_APP_COOKIE_KEY, token);
            history.push(`/oauth?appToken=${token}&appName=Dashboard`);
           
        }catch(e){

            //login with 
            if(e.message.indexOf("auth/account-exists") !== -1){
                swal('Inner Circle Deets', `This email is logged in with another social platform`, 'info');
            }else{
                swal('Inner Circle Deets', 'Could not login with Google', 'info');
            }
            
            console.log(e.message)
        }finally{
            setLoading(false);
        }
    }

    const onFacebook = async () => {
        try {
            setLoading(true);
            await signOut(getAuth());
            const auth = getAuth();
            const provider = new FacebookAuthProvider();
            provider.setCustomParameters({
                'display': 'popup'
            });
            const result = await signInWithPopup(auth, provider);

            const access_token = result["_tokenResponse"].oauthAccessToken;
            const res = await API.PostAPI('/api/facebook/oauth', {access_token:access_token}, false);
            const token = res.access_token;
            Cookies.set(process.env.REACT_APP_COOKIE_KEY, token);
            history.push(`/oauth?appToken=${token}&appName=Dashboard`);

        }catch(e){
            console.log(e.message)
           //login with 
            if(e.message.indexOf("auth/account-exists") !== -1){
                swal('Inner Circle Deets', `This email is logged in with another social platform`, 'info');
            }else{
                swal('Inner Circle Deets', 'Could not login with Google', 'info');
            }
        }finally{
            setLoading(false);
        }
    }

    return (
        <>
        
        <Container className="centralise" style={{paddingTop:250}}>
            <img src={logo} alt="logo" height={260}/>
            <h1>Join Inner Circle Deets</h1>
            <hr/>
            <Button disabled={loading} variant="light" size="lg" className="round hover dropShadow" onClick={onGoogle}>
                <img src="https://img.icons8.com/fluency/35/000000/google-logo.png" alt="Google"/>
                <strong>{loading ? "Loading...": "Continue with Google"}</strong>
            </Button>
            <br/><br/>
            <Button disabled={loading} variant="primary" size="lg" className="round hover dropShadow" onClick={onFacebook}>
                <img src="https://img.icons8.com/fluency/35/000000/facebook-new.png" alt="Facebook"/>{" "}
                <strong>{loading ? "Loading..." : "Continue with Facebook"}</strong>
            </Button>
        </Container>
        </>)
}

export default PageLogin

import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Table, FormControl, Button, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Detail from '../components/Detail';
import { AnimationDevelopers } from '../components/Lottie';
import * as API from "../utils/api";


function ContentGroup({menuCollapse}) {
    
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [code, setCode] = useState("")
    const [description, setDescription] = useState("")
    const [groups, setGroups] = useState([]);
    const [publicGroup, setPublicGroup] = useState(true);
    const [password, setPassword] = useState("");


    useEffect(() => {
        async function run(){
            try{
                const groups = await API.GetAPI('/api/groups');
                setGroups(groups || []);
            }catch(e){
                console.log(e.message);
                setGroups([]);
            }
        }

        run();
    }, [])


    const onAddGroup = async (e) => {
        e.preventDefault();

        const group = {
            name:name, 
            code:code, 
            description: description, 
            resources:[], 
            social:{},
            public: publicGroup,
            password: password,
        }

        try{
            setLoading(true);
            const res = await API.PostAPI('/api/groups', group);
            swal(res.result? 'Group Created' : 'Group Not Created', res.message, res.result? 'success': 'info');
            const groups = await API.GetAPI('/api/groups');
            setGroups(groups);
        }catch(e){
            console.log(e.message);
            swal('Something went wrong. Check your internet.')
        }finally{
            setLoading(false);
        }
    }


    const onDel = async (page) => {
        const options = {
            title: "Delete Group",
            text: `Do you want to delete ${page.name}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }
        const result = await swal(options);
        if(result){
            try{
                setLoading(true);
                const res = await API.DeleteAPI(`/api/groups/${page._id}`);
                swal('Group Delete', res.message, 'info');
                const groups = await API.GetAPI('/api/groups');
                setGroups(groups?groups:[]);
            }catch(e){
                console.log(e.message);
            }finally{
                setLoading(false);
            }
        }
    }

    const onCopy = (page) =>{ 
        navigator.clipboard.writeText(`https://app.mysocial360.com/innercircledeets/deets/${page.code}`).then( 
            () => swal(page.name, `Copied Code for ${page.code}`, 'success')
        )
    }


    return ( <Container fluid={menuCollapse ? "xs" : "xl" }style={{paddingTop:80}}>
             <Form onSubmit={onAddGroup}>             
                <Row xs={1} sm={1} md={2}>
               
                    <Col>
                        <AnimationDevelopers width={500} title="BOS Groups"/>
                    </Col>
                    <Col>
                        <Alert variant="light" className="dropShadow">
                            <Alert.Heading><h2>Create Grouped Content</h2></Alert.Heading>
                            <hr/>
                            <Form.Label>Group Name ({name.length}/50)</Form.Label>
                            <FormControl placeholder="Group Name" type="text"  name="name" maxLength={50} value={name} onChange={e=>setName(e.target.value)} />
                            <br/>
                            <Form.Label>Join Code ({code.length}/50)</Form.Label>
                            <FormControl placeholder="Join Code" name="code" type="text" maxLength={50} value={code} onChange={e=>setCode(e.target.value)}/>
                            <br/>
                            <Form.Label>Description ({description.length}/200)</Form.Label>
                            <FormControl  placeholder="Description" value={description} onChange={e=>setDescription(e.target.value)} type="text" as="textarea" style={{height:80}} maxLength={200} />
                            <br/>
                            {
                                publicGroup ? null :
                                <div>
                                    <Form.Label>Group Password ({password.length}/50)</Form.Label>
                                    <FormControl  placeholder="Group Password" value={password} onChange={e=>setPassword(e.target.value)} type="password" maxLength={50} />
                                </div>
                            }
                            <br/>
                            <Form.Check checked={!publicGroup} onChange={e=>setPublicGroup(!publicGroup)} label="Group is private" />
                            <br/>
                            <Button disabled={loading} variant="info" type="submit" className="round hover dropShadow">
                                <strong>{loading?"LOADING...":"ADD NEW GROUP"}</strong>
                            </Button>  
                        </Alert>
                    </Col>
                </Row>

                    <br/><br/>
                    <h2>GROUPED CONTENT</h2>
                    <Table variant="light" className="dropShadow">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>NAME</th>
                                <th>CODE</th>
                                <th>DESCRIPTION</th>
                                <th>PUBLIC</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                groups.map((page, index)=>
                                
                                <tr key={page._id}>
                                    <td>{index+1}</td>
                                    <td>{page.name}</td>
                                    <td>{page.code}</td>
                                    <td><Detail max={100} text={page.description} /></td>
                                    <td>{page.public ? "YES":"NO"}</td>
                                    <td>
                                        <Button disabled={loading} variant="info" as={Link} to={`/content/preview/${page._id}`} className="hover round dropShadow">
                                            <img src="https://img.icons8.com/fluency-systems-filled/24/ffffff/file-preview.png" alt="f"/>
                                            PREVIEW
                                        </Button>
                                        <Button disabled={loading} variant="primary" onClick={()=>onCopy(page)} className="hover round dropShadow">
                                            <img src="https://img.icons8.com/ios-glyphs/30/ffffff/link--v1.png" alt="e"/>
                                            COPY
                                        </Button>
                                        <Button disabled={loading} variant="info"  as={Link} to={`/content/edit/${page._id}`} className="hover round dropShadow">
                                            <img src="https://img.icons8.com/ios-glyphs/30/ffffff/edit--v1.png" alt="e"/>
                                            EDIT
                                        </Button>
                                        <Button disabled={loading} variant="danger" onClick={()=>onDel(page)} className="hover round dropShadow">
                                            <img alt="v" src="https://img.icons8.com/ios-glyphs/30/ffffff/filled-trash.png"/>
                                            DEL
                                        </Button>
                                    </td>
                                </tr>
                                )
                            }
                        </tbody>
                    </Table>
        </Form>
        <br/><br/><br/><br/>
    </Container>
    )
}

export default ContentGroup

import React, { useContext, useState } from 'react'
import { Container, Form, Button, FormControl, Alert, Modal, ProgressBar, Row, Col } from 'react-bootstrap'
import { ContextUser, ContextSetUser } from '../App';
import * as API from "../utils/api";
import swal from 'sweetalert'; 

import { getStorage, ref,uploadBytesResumable, getDownloadURL } from "firebase/storage";

function ContentCoaches({menuCollapse}) {

    const [loading, setLoading] = useState(false);
    const user = useContext(ContextUser);
   
    const setUser = useContext(ContextSetUser);
    const [image, setImage] = useState(user?.image);
    
    const [progress, setProgress] = useState(0);
    const [progressPopup, setProgressPopup]= useState(false);
    console.log(user);
    const onUpdate = async (e) => {
        e.preventDefault();
        try{
            const update =  {
                name:e.target.name.value,
            };
            setLoading(true);
            const res = await API.PatchAPI('/api/user', update);
            user.name = update.name;
            swal(res.message);
        }catch(e){
            console.log(e.message)
        }finally{
            setLoading(false);
        }
    };
    

    const onUpdateImage = async (e) => {
        
        const file = e.target.files[0];
        const mbs = 2;
        const maxSize = mbs * 1000 * 1000;

        if(file.size > maxSize){
            swal(`Image is too large. Image should be less than ${mbs}MB`); 
            return;
        }

        if(!file.type.match(/image/gmi)){
            swal(`Invalid image`); 
            return;
        }

        // Create a root reference
        const storage = getStorage();

        // Create a reference to 'profile.png'
        const storageRef = ref(storage, `${user.firebase_uid}/profile.png`);

        const uploadTask = uploadBytesResumable(storageRef, file);

        const onStateChange =  (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(parseInt(progress));
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                console.log('Upload is paused');
                break;
                case 'running':
                console.log('Upload is running');
                break;
                default:break;
            }
        }

        const onError = (error) => {
            swal(error.message)
            setProgressPopup(false)
        }

        const onComplete = async () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            try{
                const update =  {
                    image:downloadURL
                };
                setLoading(true);
                const res = await API.PatchAPI('/api/user', update);
                user.image = update.image;
                setImage(downloadURL);
                swal(res.message);
            }catch(e){
                console.log(e.message)
            }finally{
                setLoading(false);
                setProgressPopup(false)
            }
        }

        
        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', onStateChange, onError, onComplete);
        setProgressPopup(true);
    }

    const onCopy = (context) => {
        navigator.clipboard.writeText(context).then(()=> swal(`Copied your join link ${context}`));
    }

    
    const onCode = async (e) => {
        e.preventDefault();
        try{
            
            const update = {
                code: e.target.code.value
            }
            
            setLoading(true);
            const res = await API.PatchAPI(`/api/user/code`, update);
            if(res.result){
                user.code = res.id;
                user.code_update = true
                setUser({...user})
                swal(res.message)
            }else{
                swal(res.message)
            }
        }catch(e){
            console.log(e.message);
        }finally{
            setLoading(false);
        }
    }

    return (
        <Container fluid={menuCollapse ? "xs" : "xl" } className="centralise" style={{paddingTop:30}}>
            <Alert variant="light"  className="dropShadow">
                <input id="input" onChange={onUpdateImage} type="file" style={{display:"none"}} accept="image/*"/>
                <img src={image} width={150} height={150} className="dropShadow" alt="coach" style={{borderRadius:"50%", padding:14}} />
                <br/>
                <Button variant="primary" className="round" onClick={()=>document.getElementById('input').click()}>
                    <img src="https://img.icons8.com/ios-glyphs/30/ffffff/user--v1.png" alt="profile"/>{" "}
                    <strong>Upload New Profile Image</strong>
                </Button>
                
                <br/>

                <Form onSubmit={onUpdate}>
                    <Form.Label><h4>Display Name</h4></Form.Label>
                    <FormControl required name="name" maxLength={50} defaultValue={user?.name} type="name" size="lg" placeholder="Display Name" />
                    <br/>
                    <Row xs={1} sm={2} md={3}>
                        <Col>
                            <Button variant="outline-warning" size="lg" className="round dropShadow" onClick={()=>onCopy(user.register_url)}>
                                <img src="https://img.icons8.com/ios-glyphs/30/ffc107/login-rounded-right--v1.png" alt="register"/>
                                <strong>Copy Affiliate Share Link</strong>
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={loading} variant={loading?"outline-info":"outline-primary"} type="submit" className="round hover dropShadow" size="lg">
                                {   loading?
                                    <img src="https://img.icons8.com/glyph-neue/30/17a2b8/approve-and-update.png" alt="update"/>:
                                    <img src="https://img.icons8.com/glyph-neue/30/007bff/approve-and-update.png" alt="update"/>
                                }
                                <strong>{loading ? "Loading..." : "Update Info"}</strong>
                            </Button>
                        </Col>
                       <Col>
                            <Button variant="outline-danger" size="lg" className="round dropShadow" onClick={()=>onCopy(user.share_url)}>
                                <img src="https://img.icons8.com/material-rounded/30/dc3545/copy.png" alt="copy"/>
                                <strong>Copy Direct Download Link</strong>
                            </Button>
                        </Col>
                    </Row>
                    
                    <br /><br />
                    
                   
                </Form>
                {
                    user.code_updated ? <h6>Referral Code: <strong>{user.code}</strong></h6>
                :<>
                    <Form onSubmit={onCode}>
                    <Form.Label><h4>Your Referral Code: <strong>{user.code}</strong></h4></Form.Label>
                    <br/>
                    <FormControl required name="code" defaultValue={user.code} type="code" size="lg" placeholder="Referral Code..." />
                                    
                    <br/><br/>
                    <Button size="lg" disabled={loading} variant={loading?"info":"primary"} type="submit" className="round hover dropShadow">
                        <img src="https://img.icons8.com/ios-filled/20/ffffff/digi-id.png" alt="join"/>{" "}
                        <strong>{loading?"Loading...":"Update Referral Code"}</strong>
                    </Button>
                </Form>
                </>
                }
            </Alert>
            <>
            
            
            </>
            <Modal show={progressPopup} onHide={()=>setProgressPopup(false)}>
                <Modal.Header>
                    <Modal.Title>UPLOADING IMAGE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Uploading {progress}%</h4>
                    <br/>
                    <ProgressBar style={{height:15}} variant="primary" min={0} max={100} now={progress} striped={true} animated={true} />
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default ContentCoaches

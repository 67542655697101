import React from 'react'

function TypeIcon ({type}) {
    
    if(type === "Video"){
        return <img src="https://img.icons8.com/fluency/30/ffffff/video.png" alt="video" style={{float:"left"}}/>
    } else if (type === "Image") {
        return <img src="https://img.icons8.com/color-glass/30/ffffff/image.png" alt="icon" style={{float:"left"}}/>
    } else if (type === "PDF") {
        return <img src="https://img.icons8.com/color/30/ffffff/pdf-2.png" alt="icon" style={{float:"left"}}/>
    } else if (type === "Link") {
        return <img src="https://img.icons8.com/external-flatart-icons-flat-flatarticons/30/ffffff/external-pdf-file-online-learning-flatart-icons-flat-flatarticons.png" style={{float:"left"}}  alt="icon"/>
    } else if (type === "Google Doc") {
        return <img src="https://img.icons8.com/color/30/ffffff/google-forms.png" alt="icon" style={{float:"left"}}/>
    } else if (type === "Google Form" ){
        return <img src="https://img.icons8.com/color/48/ffffff/google-forms-new-logo-1.png" alt="icon" style={{float:"left"}}/>
    }else if(type === "Bible Scripture"){
        return <img src="https://img.icons8.com/external-vitaliy-gorbachev-flat-vitaly-gorbachev/48/000000/external-bible-easter-vitaliy-gorbachev-flat-vitaly-gorbachev.png"  style={{float:"left"}} alt="icon"/>
    }else { 
        return null;
    } 
}

export default TypeIcon
import React from 'react'
import { Container } from 'react-bootstrap';
import { AnimationComingSoon } from '../components/Lottie';


function ContentIntegrations({menuCollapse}) {
    return (
        <Container fluid={menuCollapse ? "xs" : "xl" } className="centralise" style={{paddingTop:30}}>
            <AnimationComingSoon />
        </Container>
    )
}

export default ContentIntegrations

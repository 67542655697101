import React, { useEffect, useState } from 'react';
import { Container, Alert, Row, Col, Button, Image } from 'react-bootstrap';
import * as API from '../utils/api';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

function ContentAudience({ menuCollapse }) {
  const [groupId, setGroupId] = useState('-1');
  const [groups, setGroups] = useState([]);
  const [audience, setAudience] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;

  // function to make a buttons for the table
  const buttonFormatter = (rowContents, row) => {
    return (
      <Container>
        <Row>
          <Col>
            <Button
              variant="primary"
              onClick={() => alert(`The invited id is: ${row._id}`)}
            >
              Invite
            </Button>
          </Col>
          <Col>
            <Button
              variant="outline-danger"
              onClick={() => alert(`The banned id is: ${row._id}`)}
            >
              Ban
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };

  // function to make image component for the table
  const imageFormatter = (rowContents, row) => {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} sm={4} md={4}>
            <Image
              src={row.image}
              width="200"
              height="200"
              fluid
              roundedCircle
            />
          </Col>
        </Row>
      </Container>
    );
  };

  const products = [
    {
      _id: 1,
      name: 'George',
      email: 'Monkey',
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png',
    },
    {
      _id: 2,
      name: 'Jeffrey',
      email: 'Giraffe',
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png',
    },
    {
      _id: 3,
      name: 'Alice',
      email: 'Giraffe',
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png',
    },
    {
      _id: 4,
      name: 'Alice',
      email: 'Tiger',
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png',
    },
    {
      _id: 5,
      name: 'Alice',
      email: 'Tiger',
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png',
    },
    {
      _id: 6,
      name: 'Alice',
      email: 'Tiger',
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png',
    },
    {
      _id: 7,
      name: 'Alice',
      email: 'Tiger',
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png',
    },
    {
      _id: 8,
      name: 'Alice',
      email: 'Tiger',
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png',
    },
    {
      _id: 9,
      name: 'Alice',
      email: 'Tiger',
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png',
    },
    {
      _id: 10,
      name: 'Alice',
      email: 'Tiger',
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png',
    },
    {
      _id: 11,
      name: 'Alice',
      email: 'Tiger',
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png',
    },
    {
      _id: 12,
      name: 'Alice',
      email: 'Tiger',
      image:
        'https://media.geeksforgeeks.org/wp-content/uploads/20210425000233/test-300x297.png',
    },
  ];

  /**
   * List of columns the table should have,
   * dataField: which field the table should be looking for from response data,
   * text: title of the column
   * formatter: html rendering
   * NOTE: img and actions are not from response
   * */
  const columns = [
    { dataField: '_id', text: 'Id' },
    { dataField: 'name', text: 'Name' },
    { dataField: 'email', text: 'Email' },
    { dataField: 'img', text: 'Image', formatter: imageFormatter },
    { dataField: 'actions', text: 'Actions', formatter: buttonFormatter },
  ];

  const defaultSorted = [
    {
      dataField: 'name',
      order: 'desc',
    },
  ];

  const pagination = paginationFactory({
    page: 2,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
  });

  useEffect(() => {
    async function run() {
      try {
        //get all the groups
        const groups = await API.GetAPI('/api/groups');
        setGroups(groups ? groups : []);
        console.log(groups);
      } catch (e) {
        console.log(e.message);
      }
    }
    run();
  }, []);

  useEffect(() => {
    async function run() {
      try {
        if (groupId !== '-1') {
          //get all the groups
          const res = await API.GetAPI(`/api/groups/group/${groupId}/audience`);
          if (res.result) {
            const { audience } = res;
            setAudience(audience ? audience : []);
          }
        }
      } catch (e) {
        console.log(e.message);
      }
    }
    run();
  }, [groupId]);

  return (
    <Container
      fluid={menuCollapse ? 'xs' : 'xl'}
      className="centralise"
      style={{
        paddingTop: 30,
        width: menuCollapse ? 'calc(100% - 10px)' : 'calc(100% - 280px)',
      }}
    >
      <Alert>
        <Alert.Heading>
          <h1>Audience</h1>
        </Alert.Heading>
      </Alert>
      <br />
      <select value={groupId} onChange={(e) => setGroupId(e.target.value)}>
        <option value="-1">Select Group</option>
        {groups.map((group) => (
          <option key={group._id} value={group._id}>
            {group.name}
          </option>
        ))}
      </select>
      <br />

      <br />
      {/** Insert the table here
       * audience = [] in an array
       * data in each array looks like
       * {
       *  _id
       *  name
       *  email
       *  image
       * }
       *
       */}
      <ToolkitProvider keyField="id" data={products} columns={columns} search>
        {(props) => (
          <>
            <SearchBar {...props.searchProps} />
            <ClearSearchButton {...props.searchProps} />
            <hr />
            <BootstrapTable
              striped={true}
              hover={true}
              bordered={true}
              condensed={true}
              defaultSorted={defaultSorted}
              pagination={pagination}
              {...props.baseProps}
            />
          </>
        )}
      </ToolkitProvider>

      <br />
    </Container>
  );
}

export default ContentAudience;
